/* eslint-disable no-console */
import React from 'react'
import {
  Box,
  Typography,
  Button,
  ButtonProps,
  styled,
  Stack
} from '@mui/material'
import { ArrowBack as ArrowIcon, Add as AddIcon } from '@mui/icons-material'
import { AdminContext } from 'context/AdminContext'
import { UserAdminContext } from 'context/UserAdminContext'
import { UserProfile } from 'model/organization'
import { getUsersFromEdges } from 'utils/adminData'
import LoadingIndicator from 'components/LoadingIndicator'
import UsersTable from './UsersTable'

const CreateButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: '#FFFFFF',
  padding: '11px 20px 11px 13px',
  '&:hover': {
    backgroundColor: '#050B0F'
  }
}))

interface UsersPageProps {
  epId: string
}

const UsersPage = ({ epId }: UsersPageProps) => {
  const {
    getEmployerData,
    getEmployerError,
    getEmployerResponse,
    getEmployerIsLoading
  } = React.useContext(AdminContext)

  const {
    getUsers,
    getUsersError,
    getUsersResponse,
    getUsersIsLoading,
    getSelectedUser,
    selectedUser,
    selectedUserError,
    selectedUserIsLoading,
    changePermission,
    changePermissionSuccess,
    changePermissionError,
    changePermissionUnauthorized,
    deactivateUser,
    deactivateUserError,
    deactivateUserSuccess,
    deactivateUserUnauthorized,
    activateUser,
    activateUserError,
    activateUserSuccess,
    activateUserUnauthorized
  } = React.useContext(UserAdminContext)

  const [employer, setEmployer] = React.useState(getEmployerResponse)
  const [users, setUsers] = React.useState<UserProfile[]>([] as UserProfile[])

  React.useMemo(() => {
    if (epId) {
      getEmployerData(epId)
      getUsers(epId)
    }
  }, [epId, getEmployerData, getUsers])

  React.useMemo(() => {
    if (getEmployerResponse) {
      setEmployer(getEmployerResponse)
    }
  }, [getEmployerResponse])

  React.useMemo(() => {
    if (getUsersResponse) {
      setUsers(
        getUsersFromEdges(getUsersResponse?.employerStaffAccounts?.edges)
      )
    }
  }, [getUsersResponse])

  const createUser = () => {
    console.log('user created')
  }

  const submitRoleChanges = (
    employerId: string,
    userId: string,
    roles: string[]
  ) => {
    changePermission(userId, employerId, roles)
  }

  const handleDeactivateUser = (id: string, employerID: string) => {
    deactivateUser(id, employerID)
  }

  return (
    <Box>
      {getEmployerIsLoading && !getEmployerError && !employer ? (
        <LoadingIndicator />
      ) : (
        <Box>
          <Button
            sx={{
              marginLeft: 'auto',
              marginRight: 0,
              marginBottom: '40px',
              borderRadius: 0,
              padding: '0 0 6px 0',
              '&:hover': {
                backgroundColor: 'transparent',
                borderBottom: '1px solid black',
                padding: '0 0 5px 0'
              }
            }}
            href='/admin-portal/'
            startIcon={<ArrowIcon />}
          >
            Back to Employer Admin Portal
          </Button>
          {getEmployerError ? (
            <Box>
              <Typography>
                There was an error retrieving the employer.
              </Typography>
            </Box>
          ) : (
            <Box>
              {employer ? (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '20px 5px',
                    marginBottom: '40px'
                  }}
                >
                  <Typography variant='h1'>{employer?.name}</Typography>
                  {employer.status === 'active' && (
                    <CreateButton onClick={createUser}>
                      <Stack alignItems='center' direction='row' gap={1}>
                        <AddIcon />
                        Add New User
                      </Stack>
                    </CreateButton>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography>The employer was not found.</Typography>
                </Box>
              )}
              {employer && employer?.status === 'active' ? (
                <UsersTable
                  users={users}
                  error={getUsersError}
                  isLoading={getUsersIsLoading}
                  epId={epId}
                  submitRoleChanges={submitRoleChanges}
                  roleChangeError={changePermissionError}
                  roleChangeSuccess={changePermissionSuccess}
                  roleChangeUnauthorized={changePermissionUnauthorized}
                  deactivateUser={handleDeactivateUser}
                  deactivateUserError={deactivateUserError}
                  deactivateUserSuccess={deactivateUserSuccess}
                  deactivateUserUnauthorized={deactivateUserUnauthorized}
                  activateUser={activateUser}
                  activateUserSuccess={activateUserSuccess}
                  activateUserError={activateUserError}
                  activateUserUnauthorized={activateUserUnauthorized}
                  getSelectedUser={getSelectedUser}
                  selectedUser={selectedUser}
                  selectedUserError={selectedUserError}
                  selectedUserIsLoading={selectedUserIsLoading}
                />
              ) : (
                <Box>
                  <Typography>EP is currently inactive</Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default UsersPage
