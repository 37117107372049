/* eslint-disable no-console */
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { AdminContext } from 'context/AdminContext'
import { Box, Grid } from '@mui/material'
import { getOrganizationsArray } from 'utils/adminData'
import { Organization } from 'model/organization'
import { UserAdminProvider } from 'context/UserAdminContext'
import EmployerTable from './EmployerTable'
import UsersPage from './UsersPage'

const AdminPortal = () => {
  const {
    organizationEdges,
    getOrganizationsError,
    getOrganizationsIsLoading,
    loadSelectedEmployer
  } = useContext(AdminContext)

  // construct Orgnizations array
  const orgs: Organization[] = getOrganizationsArray(organizationEdges)
  const { epId } = useParams()

  return (
    <Grid
      sx={{
        maxWidth: 1460,
        width: '90%',
        height: '100vh',
        padding: '40px 0',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box sx={{ width: '100%', height: '100%', paddingBottom: '200px' }}>
        {epId ? (
          <UserAdminProvider>
            <UsersPage epId={epId} />
          </UserAdminProvider>
        ) : (
          <EmployerTable
            isLoading={getOrganizationsIsLoading}
            error={getOrganizationsError}
            organizations={orgs}
            loadSelectedEmployer={loadSelectedEmployer}
          />
        )}
      </Box>
    </Grid>
  )
}
export default AdminPortal
