/* eslint-disable no-console */
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { ApolloError } from '@apollo/client'
import { Navigate } from 'react-router-dom'
import { AlertColor, Box } from '@mui/material'
import { AccountContext } from 'context/AccountContext'
import { AdminContext } from 'context/AdminContext'
import { hasAccessToAdminPortal } from 'utils/authCheck'
import { getFormattedDate } from 'utils/adminData'
import {
  EmployerLoginType,
  UpdateEmployerNameResponseType
} from 'model/organization'
import AlertErrorMessage from 'components/AlertErrorMessage'
import DeactivateConfirm from 'components/DeactivateConfirm'
import SidePanelHeader from './SidePanelHeader'
import SidePanelBody from './SidePanelBody'
import SidePanelFooter from './SidePanelFooter'

interface EmployerSidePanelProps {
  closeSidePanel: () => void
}

const EmployerSidePanel = ({ closeSidePanel }: EmployerSidePanelProps) => {
  const errorPathname = '/unauthorized'
  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const {
    selectedEmployer,
    hasAccessToSearchParamEmployer,
    updateEmployerName,
    updateEmployerNameError,
    updateEmployerNameResponse,
    loginType
  } = useContext(AdminContext)

  const [userId, setUserId] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [lastEpUpdated, setLastEpUpdated] = useState(updateEmployerNameResponse)
  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [editSeverity, setEditSeverity] = useState<AlertColor>('error')
  const [openEditAlert, setOpenEditAlert] = useState<boolean>(false)
  const [selectionSet, setSelectionSet] = useState(
    updateEmployerNameError
      ? updateEmployerNameError?.graphQLErrors[0]?.extensions?.selectionSet
      : ''
  )
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false)
  const [deactivateEmployerInProgress, setDeactivateEmployerInProgress] =
    useState<boolean>(false)

  const handleEditError = useCallback(
    (requestError: ApolloError | undefined) => {
      const message =
        requestError && requestError?.graphQLErrors[0]
          ? requestError?.graphQLErrors[0]?.message
          : 'There was an error. Please try again.'
      setEditSeverity('error')
      setEditErrorMessage(message)
      setOpenEditAlert(true)
      setTimeout(() => {
        setOpenEditAlert(false)
      }, 5000)
    },
    []
  )

  const handleEditSuccess = useCallback(
    (
      response: UpdateEmployerNameResponseType | undefined | null,
      isUpdateName?: boolean
    ) => {
      const message = isUpdateName
        ? 'The organization name has been updated.'
        : `The organization ${response?.name} has successfully been created.`
      setEditSeverity('success')
      setEditErrorMessage(message)
      setOpenEditAlert(true)
      setTimeout(() => {
        setOpenEditAlert(false)
      }, 5000)
    },
    []
  )

  const deactivateEmployer = () => {
    setTimeout(() => {
      setDeactivateEmployerInProgress(false)
    }, 10000)
    console.log('EP has been deactivated')
  }

  const activateEmployer = () => {
    console.log('EP has been reactivated')
  }

  useEffect(() => {
    if (currentAccount && currentAccount.userId) {
      setUserId(currentAccount?.userId)
      setIsAdmin(hasAccessToAdminPortal(currentAccount))
    }
  }, [currentAccount])

  useMemo(() => {
    if (
      updateEmployerNameResponse &&
      updateEmployerNameResponse !== lastEpUpdated
    ) {
      setLastEpUpdated(updateEmployerNameResponse)
      handleEditSuccess(updateEmployerNameResponse, true)
    }
  }, [updateEmployerNameResponse, lastEpUpdated, handleEditSuccess])

  useMemo(() => {
    if (
      updateEmployerNameError &&
      selectionSet !==
        updateEmployerNameError?.graphQLErrors[0]?.extensions?.selectionSet
    ) {
      handleEditError(updateEmployerNameError)
    }
  }, [updateEmployerNameError, handleEditError, selectionSet])

  if (!selectedEmployer) return null

  const submitName = async (name: string) => {
    setSelectionSet('')
    updateEmployerName({ name, id: selectedEmployer.id })
  }

  const createUser = () => {
    console.log('user has been created')
  }

  const formattedLoginType = (authType: EmployerLoginType): string => {
    if (!authType) return 'NA'
    if (authType && authType.strategy === 'DOMAIN_LOCK') {
      return `${authType.idpType} SSO`
    }
    if (authType && !authType.strategy) {
      return 'NA'
    }
    return 'Invitation Only'
  }

  if (hasAccessToSearchParamEmployer && isAdmin) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box>
          <AlertErrorMessage
            severity={editSeverity}
            message={editErrorMessage}
            openAlert={openEditAlert}
            setOpenAlert={setOpenEditAlert}
          />
        </Box>
        {isDeactivating ? (
          <Box>
            <DeactivateConfirm
              isEp
              epId={selectedEmployer.id}
              closeSidePanel={closeSidePanel}
              identifier={selectedEmployer.code}
              deactivateEp={deactivateEmployer}
              deactivateEpInProgress={deactivateEmployerInProgress}
              setIsDeactivating={setIsDeactivating}
              setDeactivateEpInProgress={setDeactivateEmployerInProgress}
            />
          </Box>
        ) : (
          <Box>
            <SidePanelHeader
              epId={selectedEmployer.id}
              submitName={submitName}
              userId={userId}
              code={selectedEmployer.code}
              name={selectedEmployer.name}
              status={selectedEmployer.status}
            />
            <SidePanelBody
              loginType={formattedLoginType(loginType)}
              created={getFormattedDate(selectedEmployer.insertedAt)}
              id={selectedEmployer.id}
            />
            <SidePanelFooter
              epId={selectedEmployer.id}
              epStatus={selectedEmployer.status}
              setIsDeactivating={setIsDeactivating}
              closeSidePanel={closeSidePanel}
              activateEP={activateEmployer}
            />
          </Box>
        )}
      </Box>
    )
  }
  return <Navigate to={errorPathname} />
}

export default EmployerSidePanel
